<template>
    <section>
        <section class="d-flex flex-wrap poppins align-center">
            <v-btn text dense 
                @click="(prevRoute && prevRoute.name === 'Instructor Enrollment') ? $router.back() : $router.push({ name: 'Instructor Enrollment' , query: { search: '', page: 1, paginate: 10 }})"
                class="mr-1 pa-0 f14">
                <v-icon small>
                    mdi-chevron-left
                </v-icon>
                BACK
            </v-btn>
            <span class="f14">/</span> <span class="fw600 primary--text f14 ml-2">{{ title  }}</span>
        </section>

        <section>
            <section class="d-flex flex-wrap justify-space-between">
                <SearchBar 
                    :placeholder="'Search student'"
                    :value.sync="search" 
                    @search="() => { page = 1, getData()}" 
                    @clear="() => { search = '', page = 1, getData()}"
                    class="mt-3 mb-5"/>
                <section class="d-flex flex-row ml-auto">
                    <FilterMenu
                        :initialFilter="filter"
                        @resetFilters="clearFilters()"
                        @applyFilters="() => {
                                page = 1,
                                updateQuery()
                            }"
                    >
                    <section>
                        <FormSelectObject
                            :label="'MODE OF PAYMENT'"
                            :items="mode_of_payment"
                            :value.sync="filter.mode_of_payment"
                            class="mb-3"
                        />

                        <FormSelectObject
                            :label="'TYPE OF PAYMENT'"
                            :items="filter.mode_of_payment === '' ? type_of_payment : (filter.mode_of_payment === 'online' ? online_type_of_payment : offline_type_of_payment)"
                            :value.sync="filter.type_of_payment"
                            :disabled="filter.mode_of_payment == 'online'"
                            class="mb-3"
                        />

                        <FormSelectObject
                            :label="'PAYMENT STATUS'"
                            :items="payment_status"
                            :value.sync="filter.invoice_status"
                            class="mb-3"
                        />
                        
                        <FormSelectObject
                            :label="'ENROLLMENT STATUS'"
                            :items="enrollment_status"
                            :value.sync="filter.enrollment_status"
                            class="mb-3"
                        />

                        <FormTextFieldOptional
                            :label="'ENROLLED BY'"
                            :value.sync="filter.enrolled_by" />

                        <div class="mt-3">
                            <FormLabel class="mb-1" :label="'TRANSACTION DATE (YYYY-MM-DD)'" />
                        </div>
                        <label class="caption">FROM:</label>
                        <FormDatePicker 
                            :val="filter.date_from"
                            :max="maxDate"
                            class_="col-12 mb-3"
                            @apply="(e) => {
                                filter.date_from = e
                                minDate = e
                            }"/>

                        <label class="caption">TO:</label>
                        <FormDatePicker 
                            :val="filter.date_to"
                            :min="minDate"
                            class_="col-12 mb-3"
                            @apply="(e) => {
                                filter.date_to = e
                                maxDate = e
                            }"/>
                    </section>
                </FilterMenu>
                    <ButtonExport 
                        @click="exportInvoices"
                        :loading="exportLoading"
                        :disabled="loading || enrollees.length === 0"
                        class="ma-1"/>
                </section>
            </section>

            <section class="mb-3 text-right">
                <FormLabel :label="`${totalCount} result/s`" v-if="totalCount !== 0"/>
                <FormLabel :label="`-`" v-else/>
            </section>
            
            <v-data-table
                :loading="loading"
                :headers="instructorCourseStudentsEnrollment"
                :items="enrollees"
                hide-default-footer
                :items-per-page.sync="itemsPerPage"
                class="text--center custom-border poppins f14 mt-2">
                <template v-slot:item.name="{ item }" >
                    <div class="text-no-wrap">
                        {{ item.name }}
                    </div>
                </template>
                <template v-slot:item.mode_of_payment="{ item }" >
                    <span class="text-uppercase">
                        {{ item.mode_of_payment }}
                    </span>
                </template>
                <template v-slot:item.date_enrolled="{ item }" >
                    {{ $dateFormat.ymd(item.date_enrolled) }}
                </template>
                <template v-slot:item.date_approved="{ item }" >
                    {{ $dateFormat.ymd(item.date_approved) }}
                </template>
                <template v-slot:item.official_receipt="{ item }" >
                    <v-btn 
                        v-if="item.mode_of_payment === 'online'  && item.invoice_status !== 4" outlined 
                        color="primary" dense class="pa-2 f10" @click="requestOR(item)"
                        :loading="invoiceIDForOR === item.invoice_id && requestORLoading"
                        small> Request OR </v-btn>
                    <a
                        v-if="item.mode_of_payment === 'offline' && item.official_receipt"
                        :href="item?.official_receipt?.url"
                        class="primary--text text-decoration-none f10"
                        target="_blank"
                        >
                        <v-icon small class="mr-1" dense>mdi-open-in-new</v-icon>
                        View OR
                    </a>
                </template>
                <template v-slot:item.payment_status_action="{ item }">
                    <span dense class="secondary-2--text px-2 border" v-if="item.invoice_status === 1" style="background-color: #7BC14533">APPROVED</span>
                    <span dense class="secondary-2--text px-2 border" v-if="item.invoice_status === 2" style="background-color: #BDBDBD33">PENDING</span>
                    <span dense class="secondary-2--text px-2 border" v-if="item.invoice_status === 3" style="background-color: #F8835E33">DISQUALIFIED</span>
                    <span dense class="secondary-2--text px-2 border" v-if="item.invoice_status === 4">CANCELLED</span>
                </template>
                <template v-slot:item.enrollment_status_action="{ item }">
                    <span dense class="secondary-2--text px-2 border" v-if="item.enrollment_status === 1" style="background-color: #7BC14533">ENROLLED</span>
                    <span dense class="secondary-2--text px-2 border text-no-wrap" v-if="item.enrollment_status === 2">NOT ENROLLED</span>
                </template>
                <template v-slot:item.nomination_form="{ item }" >
                    <a
                        v-if="item.nomination_form && item.nomination_form.url !== ''"
                        :href="item.nomination_form.url"
                        class="primary--text text-decoration-none f10"
                        target="_blank"
                        >
                        <v-icon small class="mr-1" dense>mdi-open-in-new</v-icon>
                        View
                    </a>
                    <span v-else>-</span>
                </template>
                <template v-slot:item.type_of_payment="{ item }" >
                    <span class="text-capitalize" v-if="item && item.type_of_payment">
                        {{ item.type_of_payment.replace('_', ' ') }}
                    </span>
                </template>
                <template v-slot:item.proof_of_payment="{ item }" >
                    <a
                        v-if="item.proof_of_payment && item.proof_of_payment.url !== ''"
                        :href="item.proof_of_payment.url"
                        class="primary--text text-decoration-none f12"
                        target="_blank"
                        >
                        <v-icon small class="mr-1" dense>mdi-open-in-new</v-icon>
                        View
                    </a>
                    <span v-else>-</span>
                </template>
            </v-data-table>
            
            <FormPagination 
                :pageCount="pageCount" 
                :page="page"
                :paginate="paginate"
                @page="(e) => {page = e, getData()}" 
                @paginate="(e) => {paginate = e, page = 1, getData()}"/>
        </section>

    </section>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import { mode_of_payment, offline_type_of_payment, online_type_of_payment, payment_status, enrollment_status } from '@/constants/payment'
import searchAndPaginateMixin from '@/mixins/searchAndPaginateMixin';
import { instructorCourseStudentsEnrollment } from '@/constants/tblheaders/enrollment';

export default {
mixins: [searchAndPaginateMixin],
mounted() {
    this.getData()
},
data: () => ({
    tab: 0,
    loading: false,
    exportLoading: false,
    statusLoading: false,
    actionItemsCanCancel: [
        { text: 'APPROVED', value: 1 },
        { text: 'PENDING', value: 2 },
        { text: 'DISQUALIFIED', value: 3 },
        { text: 'CANCELLED', value: 4 },
    ],
    item: null,
    selected: null,
    dialog: false,
    title: '',
    filter: {
        date_from: '',
        date_to: '',
        mode_of_payment: '',
        type_of_payment: '',
        invoice_status: '',
        enrolled_by: '',
        progress_status: '',
        enrollment_status: ''
    },
    modelFrom: false,
    modelTo: false,
    type_of_payment: [
        { text: 'All', value: '' },
        { text: 'CASH DEPOSIT', value: 'cash_deposit' },
        { text: 'CASH-CSC CASHIER', value: 'cash-csc_cashier' },
        { text: 'CHECK DEPOSIT', value: 'check_deposit' },
        { text: 'CHECK-CSC CASHIER', value: 'check-csc_cashier' },
        { text: 'DEBIT MEMO', value: 'debit_memo' },
        { text: 'LDDAP-ADA', value: 'lddap_pada' },
        { text: 'FUND TRANSFER', value: 'fund_transfer' },
        { text: 'LINKBIZ PORTAL', value: 'linkbiz_portal' },
    ],
    payment_status,
    mode_of_payment,
    enrollment_status,
    offline_type_of_payment,
    online_type_of_payment,
    invoiceIDForOR: null,
    prevRoute: null,
    minDate: '',
    maxDate: '',
    totalCount: 0,
    pageCount: 1,
    page: 1,
    paginate: '10',
    instructorCourseStudentsEnrollment
}),
methods: {
    ...mapActions('instructor', ['getCourseEnrolleesAction', 'requestORCSC', 'paymentExportAction', 'progressExportAction']),
    ...mapMutations(['alertMutation']),

    errorMessage(){
        this.alertMutation({
            show: true,
            text: "Something went wrong",
            type: "error"
        })
    },

    clearFilters(){
        this.filter = {
            date_from: '',
            date_from: '',
            date_to: '',
            mode_of_payment: '',
            type_of_payment: '',
            invoice_status: '',
            enrolled_by: '',
        }

        this.minDate = ''
        this.maxDate = ''
    },

    async requestOR(item) {
        this.requestORLoading = true
        this.invoiceIDForOR = item.invoice_id
        this.requestORCSC({ 
            course_uuid: this.$route.params.course_uuid, 
            user_id: item.user_id,
            email: item.email,
            reference_id: item.reference_id,
        }).then((res) => {
            this.alertMutation({
                show: true,
                text: "Requested OR successfully generated!",
                type: "success"
            })
            this.$exportToPDF(`${item.name}`, res)
        }).catch(err => {
            if (err.response && err.response.status === 404) {
                this.alertMutation({
                    show: true,
                    text: 'Official Receipt not available.',
                    type: "error"
                })
            } else {
                this.alertMutation({
                    show: true,
                    text: 'Something went wrong',
                    type: "error"
                })
            }
        }).finally(() => {
            this.requestORLoading = false
            this.invoiceIDForOR = null
        })
    },

    exportInvoices(){
        this.exportLoading = true
        this.paymentExportAction({
            course_uuid: this.$route.params.course_uuid,
            search: this.search,
            ...this.filter
        }).then((res) => {
            this.$exportToCSV(`${this.title} - Transactions`, res)
        }).catch(e => {
            this.errorMessage()
        }).finally(() => {
            this.exportLoading = false
        })
    },

    setCourseTitle(_title) {
        this.title = _title
    },

    getData() {
        if(!this.loading) {
            this.loading = true
            this.getCourseEnrolleesAction({
                course_uuid: this.$route.params.course_uuid,
                search: this.search,
                page: this.page,
                paginate: Number(this.paginate),
                ...this.filter
            }).then(res => {
                this.page = res.invoices.current_page
                this.pageCount = res.invoices.last_page
                this.paginate = String(res.invoices.per_page)
                this.title = res.title
                this.totalCount = res.invoices.total
                this.loading = false
            }).catch(() => {
                this.loading = false
                this.errorMessage()
            })
        }
    }
},
computed: {
    ...mapState('instructor', {
        enrollees: (state) => state.enrollees
    }),

    itemsPerPage(){
        return Number(this.paginate)
    }
},
watch: {
    filter: {
        handler(val) {
            if(val.mode_of_payment === 'online') {
                this.filter.type_of_payment = 'linkbiz_portal'
            }
        },
        deep: true
    },
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from          
        })
    },
}
</script>
